<template>
    <ComponentLoader v-bind="params" :key="cmpKey" />
</template>
<script>
import Vue from "vue";
import ComponentLoader from "@/components/ComponentsMobile.vue";
import propsManager from "@/build/props-manager";

Vue.use(propsManager);

export default {
    components:{
        ComponentLoader
    },
    props:{
        propsKey:{
            type: String
        }
    },
    data() {
        return {
            cmpKey: null
        }
    },
    created() {
        this.cmpKey = (new Date().getTime());
    },
    computed:{
        params(){
            return Vue.getProps(this.propsKey);
        }
    },
    methods: {
        forceUpdate(){
            this.cmpKey = (new Date().getTime());
        }
    }
}
</script>